import React from "react"
import { StaticImage } from "gatsby-plugin-image"


export default function Sidebar() {
  return (

            <div className="bg-white ">


                    <div className="flex flex-col flex-grow bg-slate-500 rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
                        <h2 className="text-2xl font-extrabold text-white sm:text-3xl">
                        <span className="block">Solution travaux</span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-slate-200">
                          Construction, rénovation, agencement... Entrer en relation avec des artisans et entreprises du bâtiment
                        </p>
                        <p className="mt-4 leading-6 text-green-200">
                          <a href="/maison/contact/">
                            <StaticImage
                              className="bg-white rounded-md"
                              src="../../images/sidebar/Malfacon.jpg" 
                              alt=""
                            />
                          </a>
                        </p>
                        <a
                        href="/maison/contact/"
                        className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 sm:w-auto"
                        >
                        Demande de devis
                        </a>
                    </div>


                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Media Kit Koudepouce
                          </h3>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                            Vous êtes professionnel ? Inscrivez-vous gratuitement pour trouver de nouveaux clients !
                          </p>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                          Bandeau, encart promotionnel, publi-information... <br />
                          <a 
                            href="/contact/"
                            className="text-slate-500 underline"
                          >
                            Renseignez-vous pour apparaître sur nos supports digitaux
                          </a>
                          </p>
                        </div>
                      </div>
                    </div>

                    
            </div>



        )
    }